<template>
  <div class="panel">
    <div class="panel-left">
      <img :src="classify.image" v-img-fallback="'https://qncweb.ktvsky.com/20211216/vadd/c6d01bc5aa27699dc276fbeab70ecfe5.png'"/>
      <p>{{ classify.name }}</p>
    </div>
    <div class="panel-right">
      <LoadMore
        ref="loadMoreRef"
        class="song-panel"
        v-if="datas.length"
        @load-more="fetchData"
        safeAreaHeight="14.6991vw"
      >
        <SongItem
            v-for="(songItem, index) in datas"
            :key="index"
            :songItem="songItem"
            :log-from="{
              song_list_source: 7,
            }"
        ></SongItem>
      </LoadMore>
      <div v-else class="panel-right-empty">
        暂无歌曲~
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineExpose, ref, reactive } from 'vue'
import SongItem from '@/components/song-item/index.vue'
import { getClasslistDetail } from '@/service/playlist'

let loadMoreRef = ref(null)

const props = defineProps({
  classify: {
    type: Object,
    default() {
      return {
        id: 0,
        image: '',
        name: ''
      }
    }
  }
})

let isRequest = false

const classify = reactive({
  ...props.classify,
  pagination: {
    p: 1,
  },
  version: {
    current: '',
    latest: ''
  }
})

let datas = ref([])

const fetchData = async () => {
  if (isRequest) {
    return
  }
  isRequest = true
  const bussinessResponseData = await getClasslistDetail({
    p: classify.pagination.p,
    type: classify.name,
    version: classify.version.latest
  })
  if (bussinessResponseData.data.length) {
    if (classify.pagination.p === 1) {
      classify.version = bussinessResponseData.version
    }
    datas.value = datas.value.concat(bussinessResponseData.data)
    classify.pagination.p++
  }
  isRequest = false
}

fetchData()

defineExpose({
  loadMoreRef,
})

</script>
<style lang="stylus" scoped>
.panel
  display flex
  justify-content space-between
  height calc(100vh - 250px)
  &-left
    width 510px
    padding-left 70px
    img
      width 300px
      height 300px
      margin-top 35px
      border-radius 20px
    p
      font-size 32px
      color #A4A4A4
      margin-top 30px
  &-right
    flex 1
    padding-top 8px
    margin 0 auto
    &-empty
      text-align center
      line-height 600px  
      margin-right 200px
      font-size 28px
      color rgba(255,255,255,0.5)
      @media screen and (max-width: 1200px)
        line-height 1000px
        margin-right 0
  @media screen and (max-width 1200px) and (min-height 1200px)
    flex-direction column
    &-left
      display flex
      flex-direction row
      align-items center
      padding-left 0
      img
        width 200px
        height 200px
        margin-top 40px
      p
        margin-left 50px
        min-width 300px
    &-right
      width 100%
      padding-left 0
      margin-top 8px
      .song-panel
        height 69.8vh !important
  @media screen and (max-height 1421px) and (min-height 1200px)
    &-right
      .song-panel
        height 60vh !important
</style>